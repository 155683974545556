import { SIDE_PANEL } from "./constants";

import { styled } from "@mui/system";

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a, b) => {
  if (a === undefined || b === undefined) {
    return [];
  }
  // ensure "a" is the bigger array
  if (b.length > a.length) {
    let t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

export const getMetadata = metadataString => {
  try {
    return metadataString === "" ? {} : JSON.parse(metadataString);
  } catch (error) {
    return {};
  }
};

export const metadataProps = function (peer) {
  return {
    isHandRaised: getMetadata(peer.metadata)?.isHandRaised,
  };
};

export const mobileChatStyle = {
  position: "fixed",
  bottom: "6rem",
  zIndex: 40,
  right: 8,
  width: "100%",
  maxWidth: 300,
  height: "65vh",
  maxHeight: 440,
};

export const isScreenshareSupported = () => {
  return typeof navigator.mediaDevices.getDisplayMedia !== "undefined";
};

export const getVideoSizeHeight = (
  length,
  openSidePanel,
  isShareScreen = null
) => {
  let size;
  let height;

  // NOTE: I think I can sequence this more efficiently
  if (isShareScreen) {
    if (openSidePanel !== SIDE_PANEL.CLOSE) {
      size = 4;
      height = "100%";
    } else {
      if (length >= 7) {
        size = 6;
        height = "25%";
      } else if (length > 4) {
        size = 6;
        height = "33%";
      } else if (length === 4) {
        size = 7;
        height = "25%";
      } else if (length === 3) {
        size = 8;
        height = "33%";
      } else if (length <= 2) {
        size = 12;
        height = "50%";
      }
    }
  } else if (openSidePanel !== SIDE_PANEL.CLOSE) {
    if (length >= 5) {
      size = 6;
      height = "33%";
    } else if (length >= 3) {
      size = 6;
      height = "50%";
    } else if (length === 2) {
      size = 12;
      height = "50%";
    } else {
      size = 12;
      height = "95%";
    }
  } else {
    if (length >= 7) {
      size = 4;
      height = "33%";
    } else if (length >= 5) {
      size = 4;
      height = "50%";
    } else if (length === 4) {
      size = 6;
      height = "50%";
    } else if (length === 3) {
      size = 4;
      height = "50%";
    } else if (length === 2) {
      size = 6;
      height = "55%";
    } else {
      size = 12;
      height = "95%";
    }
  }

  return { size, height };
};

export const makeLink = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    return urlRegex.test(part) ? (
      <Link key={i} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </Link>
    ) : (
      part
    );
  });
};

const Link = styled("a")({
  color: "blue",
  textDecoration: "underline",
});

export function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  } else {
    browserName = "an unknown browser";
  }

  return browserName;
}
