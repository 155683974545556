import { Typography } from "@mui/material";
import { styled } from "@mui/styles";

import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import InfoIcon from "@mui/icons-material/Info";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PanToolRoundedIcon from "@mui/icons-material/PanToolRounded";
import PersonIcon from "@mui/icons-material/Person";

const iconCommon = {
  width: "4.32vh !important",
  height: "4.32vh !important",
  backgroundColor: "#F8FAFF",
  borderRadius: "4px",
};

export const snackBarRoot = {
  "& .SnackbarContent-root": {
    bgcolor: "white",
  },
  "& .SnackbarItem-action": {
    alignSelf: "flex-start",
  },
};

export const brbIcon = {
  width: "4.32vh",
  height: "4.32vh",
  color: "red",
  backgroundColor: "#F8FAFF",
  borderRadius: "4px",
};

export const shareScreenIcon = {
  width: "4.32vh",
  height: "4.32vh",
  color: "#759AFC",
  backgroundColor: "#F8FAFF",
  borderRadius: "4px",
};

export const NotificationRoot = styled("div")({
  maxWidth: "50.63vw",
  minWidth: "15.63vw",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  marginLeft: "16px",
});

export const Title = styled(Typography)({
  color: "#010C3E",
  fontWeight: "bold !important",
  fontSize: "1.071rem !important" /**15px */,
  fontFamily: "Gilroy !important",
});

export const Message = styled(Typography)({
  color: "#010C3E",
  fontWeight: "500 !important",
  fontSize: "0.857rem !important" /**12px */,
  fontFamily: "Gilroy !important",
  maxWidth: "100%",
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const StyledInfoIcon = styled(InfoIcon)({
  ...iconCommon,
  color: "#4167CB",
});

export const StyledLeftIcon = styled(LogoutRoundedIcon)({
  ...iconCommon,
  color: "#4167CB",
});

export const StyledJoinIcon = styled(PersonIcon)({
  ...iconCommon,
  color: "#4167CB",
});

export const RaiseHandIcon = styled(PanToolRoundedIcon)({
  ...iconCommon,
  color: "#FFDD67",
});

export const StyledErrorIcon = styled(DangerousRoundedIcon)({
  ...iconCommon,
  color: "#CD3B33",
});
