export const defaultAudioList = [
  {
    name: "Audio1",
    id: "audio1",
    metadata: {
      description: "Artist1",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio1.mp3",
    type: "audio",
  },
  {
    name: "Audio2",
    id: "audio2",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio2.mp3",
    type: "audio",
  },
  {
    name: "Audio3",
    id: "audio3",
    metadata: {
      description: "Artist3",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio3.mp3",
    type: "audio",
  },
  {
    name: "Audio4",
    id: "audio4",
    metadata: {
      description: "Artist4",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio4.mp3",
    type: "audio",
  },
  {
    name: "Audio5",
    id: "audio5",
    metadata: {
      description: "Artist5",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio5.mp3",
    type: "audio",
  },
  {
    name: "Audio6",
    id: "audio6",
    metadata: {
      description: "Artist6",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio6.mp3",
    type: "audio",
  },
];

export const defaultVideoList = [
  {
    name: "Video1",
    id: "video1",
    metadata: {
      description: "Artist1",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video1.mp4",
    type: "video",
  },
  {
    name: "Video2",
    id: "video2",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video2.mp4",
    type: "video",
  },
  {
    name: "Video3",
    id: "video3",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video3.mp4",
    type: "video",
  },
];

export const tempParticipants = [
  {
    id: "0",
    name: "Archibald Northbottom",
    unread: 3,
  },
  {
    id: "1",
    name: "Girth Wiedenbauer",
    unread: 0,
  },
  {
    id: "2",
    name: "Fleece Marigold",
    unread: 0,
  },
  {
    id: "3",
    name: "Penny Tool",
    unread: 0,
  },
  {
    id: "4",
    name: "Hugh Saturation",
    unread: 0,
  },
  {
    id: "5",
    name: "Natalya Undergrowth",
    unread: 0,
  },
];

export const tempRaiseHand = [
  {
    name: "Archibald Northbottom",
    mic: "off",
  },
  {
    name: "Girth Wiedenbauer",
    mic: "on",
  },
];

export const tempBRB = [
  {
    name: "Fleece Marigold",
  },
];

export const tempInCall = [
  {
    name: "You",
    mic: "active",
  },
  {
    name: "Penny Tool",
    mic: "off",
  },
  {
    name: "Hugh Saturation",
    mic: "off",
  },
  {
    name: "Natalya Undergrowth",
    mic: "off",
  },
];

export const tempChat = [
  {
    id: "everyone",
    from: "Archibald",
    to: "Everyone",
    timestamp: "10:38 AM",
    message: "Let’s work on something really interesting today on the session",
  },
  {
    id: "everyone",
    from: "Penny",
    to: "Everyone",
    timestamp: "10:39 AM",
    message: "Yeah, but it should no be time consuming in my opinion",
  },
  {
    id: "everyone",
    from: "You",
    to: "Everyone",
    timestamp: "10:41 AM",
    message: "Yeah it was nice, I have given my best for the peppit designs",
  },
  {
    id: "everyone",
    from: "Girth",
    to: "Everyone",
    timestamp: "10:42 AM",
    message: "Hey Reddy, I have added you two new task, and can check it now",
  },
  {
    id: "everyone",
    from: "You",
    to: "Everyone",
    timestamp: "10:43 AM",
    message: "Thanks for letting me know, I will check it now and wait brother",
  },
  {
    id: "0",
    from: "You",
    to: "Archibald",
    timestamp: "10:50 AM",
    message: "Hey Archibald I think your mic is muted right now.",
  },
];

export const MODAL = {
  RECORDING_START: "RECORDING_START",
  RECORDING_END: "RECORDING_END",
  DENY_SHARE_SCREEN: "denyShareScreen",
  MISSING_RATE: "missingRate",
  LEAVE: "leave",
};

export const SIDE_PANEL = {
  CLOSE: "close",
  PARTICIPANTS: "participants",
  GROUP_CHAT: "groupChat",
};

export const PARTICIPANT_BEHAVIOR = {
  RAISE_HAND: "raiseHand",
  BRB: "brb",
};

export const MESSAGE_TYPES = {
  ERR: "ERR",
  JOIN: "JOIN",
  JOINED: "JOINED",
  LEAVE: "LEAVE",
  LEFT: "LEFT",
  STT: "STT",
  RATE: "RATE",
  RAISE_HAND: "RAISE_HAND",
  BRB: "BRB",
  RM_RAISE_HAND: "RM_RAISE_HAND",
  RM_BRB: "RM_BRB",
  CHAT: "CHAT",
  SHARE_SCREEN: "SHARE_SCREEN",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  DGKEY: "DGKEY",
  RECORDING_START: "RECORDING_START",
  RECORDING_END: "RECORDING_END",
  SETTINGS_SAVE_NAME: "SETTINGS_SAVE_NAME",
  SETTINGS_SAVE_PIC: "SETTINGS_SAVE_PIC",
};

export const MESSAGE_CONTENT = {
  ERR: {
    title: "Error",
    msg: "We encountered an error.",
  },
  JOINED: {
    title: "Join",
    msg: " just joined the call.",
  },
  LEFT: {
    title: "Left",
    msg: " has left the call.",
  },
  RAISE_HAND: {
    title: "Raise Hand",
    msg: " raised their hand.",
  },
  BRB: {
    title: "BRB",
    msg: " will be right back.",
  },
  SHARE_SCREEN: {
    msg: "is sharing their screen.",
  },
  RECORDING_START: {
    msg: " is recording this meeting.",
  },
  RECORDING_END: {
    msg: " has ended the recording.",
  },
};

export const CHAT_E = "Everyone";

export const GOOGLE_LOGIN_ERROR_MSG =
  "Error signing up using Google. Please try again or enter your name manually.";
export const DEFAULT_HLS_ROLE_KEY = "HLS_VIEWER_ROLE";
export const DEFAULT_HLS_VIEWER_ROLE = "hls-viewer";
export const QUERY_PARAM_SKIP_PREVIEW = "skip_preview";
export const QUERY_PARAM_VIEW_MODE = "ui_mode";
export const UI_MODE_ACTIVE_SPEAKER = "activespeaker";
export const UI_MODE_GRID = "grid";
export const MAX_TOASTS = 5;
export const RTMP_RECORD_RESOLUTION_MIN = 480;
export const RTMP_RECORD_RESOLUTION_MAX = 1280;
export const RTMP_RECORD_DEFAULT_RESOLUTION = {
  width: 1280,
  height: 720,
};

export const APP_DATA = {
  uiSettings: "uiSettings",
  chatOpen: "chatOpen",
  chatDraft: "chatDraft",
};
export const UI_SETTINGS = {
  isAudioOnly: "isAudioOnly",
};

export const DEFUALT_DEVICE_INFO = {
  deviceId: "default",
  kind: "audiooutput",
  label: "System Default Speaker Device",
};

export const DG_TIER = {
  BASE: "base",
  ENHANCED: "enhanced"
}
