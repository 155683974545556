import React, { Suspense, useEffect, useState } from "react";

import "./base.css";
import "./index.css";
import InsyghtFulLogo from "./images/logo2.svg";
import FullPageProgress from "./components/FullPageProgress";
import { AppContextProvider } from "./components/context/AppContext.js";
import { FeatureFlags } from "./services/FeatureFlags";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import { shadeColor } from "./common/utils";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import {
  brbIcon,
  shareScreenIcon,
  RaiseHandIcon,
  snackBarRoot,
  StyledErrorIcon,
  StyledInfoIcon,
  StyledLeftIcon,
  StyledJoinIcon,
} from "./components/Notifications/Notification.style.js";

import { SnackbarProvider } from "notistack";
import { BrbIcon, ShareScreenIcon } from "@100mslive/react-icons";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { HMSThemeProvider, Box } from "@100mslive/react-ui";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import myTheme from './theme';

const Conference = React.lazy(() =>
  import("./components/Conference/Conference")
);
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));
const Feedback = React.lazy(() => import("./components/Feedback/Feedback.jsx"));
const SuccessFeedback = React.lazy(() =>
  import("./components/SuccessFeedback/SuccessFeedback.jsx")
);

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "",
    font = "Gilroy",
    color = "#2F80FF",
    theme = "",
    logo = "",
    headerPresent = "false",
    metadata = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map(el => parseInt(el));

  const [themeType, setThemeType] = useState(theme);

  useEffect(() => {
    window.toggleUiTheme = () => {
      setThemeType(themeType === "dark" ? "light" : "dark");
    };
  }, [themeType]);

  useEffect(() => {
    setThemeType(theme);
  }, [theme]);

  return (
    <HMSThemeProvider
      themeType={themeType}
      aspectRatio={{ width, height }}
      theme={{
        colors: {
          brandDefault: color,
          brandDark: shadeColor(color, -30),
          brandLight: shadeColor(color, 30),
          brandDisabled: shadeColor(color, 10),
        },
        fonts: {
          sans: [font, "Inter", "sans-serif"],
        },
      }}
    >
      <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
        <AppContextProvider
          roomId={roomId}
          tokenEndpoint={tokenEndpoint}
          policyConfig={policyConfig}
          appDetails={metadata}
          logo={InsyghtFulLogo}
        >
          <ThemeProvider theme={myTheme}>
            <Box
              css={{
                bg: "$mainBg",
                w: "100%",
                ...(headerPresent === "true"
                  ? { flex: "1 1 0", minHeight: 0 }
                  : { h: "100%" }),
              }}
            >
              <AppRoutes getUserToken={getUserToken} />
            </Box>
          </ThemeProvider>
        </AppContextProvider>
      </HMSRoomProvider>
    </HMSThemeProvider>
  );
}

const RedirectToPreview = () => {
  let finalRoomID;
  let finalRole;

  const { roomId, role } = useParams();

  finalRoomID = roomId || process.env.REACT_APP_TEMP_ROOM_ID;
  finalRole = role || process.env.REACT_APP_DEFAULT_ROLE;

  if (!finalRoomID && !finalRole) {
    return <Navigate to="/" />;
  }
  if (!finalRoomID) {
    return <Navigate to="/" />;
  }
  if (["preview", "meeting", "leave"].includes(finalRoomID) && !finalRole) {
    return <Navigate to="/" />;
  }
  return (
    <Navigate to={`/preview?meetingId=${finalRoomID}&userRole=${finalRole}`} />
  );
};

function AppRoutes({ getUserToken }) {
  return (
    <Router>
      <KeyboardHandler />
      <Routes>
        <Route path="/record" element={<Conference />} />
        <Route
          path="/record/:roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference />
            </Suspense>
          }
        />
        <Route
          path="/preview"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen getUserToken={getUserToken} />
            </Suspense>
          }
        />
        <Route
          path="/preview/:roomId/"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen getUserToken={getUserToken} />
            </Suspense>
          }
        />
        <Route
          path="/meeting/:roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference />
            </Suspense>
          }
        />
        <Route
          path="/feedback/:roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Feedback />
            </Suspense>
          }
        />
        <Route
          path="/successfeedback/:roomId/:role"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <SuccessFeedback />
            </Suspense>
          }
        />
        <Route path="/preview/:roomId/:role" element={<RedirectToPreview />} />
        <Route path="/preview/:roomId/" element={<RedirectToPreview />} />
        <Route path="*" element={<RedirectToPreview />} />
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      iconVariant={{
        default: <StyledInfoIcon />,
        RAISE_HAND: <RaiseHandIcon />,
        BRB: <BrbIcon style={brbIcon} />,
        LEFT: <StyledLeftIcon />,
        JOINED: <StyledJoinIcon />,
        SHARE_SCREEN: <ShareScreenIcon style={shareScreenIcon} />,
        ERR: <StyledErrorIcon />,
      }}
      sx={snackBarRoot}
    >
      <EdtechComponent
        themeConfig={{
          aspectRatio: process.env.REACT_APP_TILE_SHAPE,
          theme: process.env.REACT_APP_THEME,
          color: process.env.REACT_APP_COLOR,
          logo: process.env.REACT_APP_LOGO,
          font: process.env.REACT_APP_FONT,
          headerPresent: process.env.REACT_APP_HEADER_PRESENT,
          metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
        }}
        getUserToken={defaultGetUserToken}
      />
    </SnackbarProvider>
  );
}
